import {createTheme} from '@mui/material/styles';

const palette = {
  mode: 'dark',
  background: {
    paper: '#2a2b32',
    default: '#222328',
    light: '#2a2b32',
    dark: '#202025',
  },
  primary: {
    main: '#F2B310',
    action: '#FFFFFF',
  },
  secondary: {
    main: '#F2B310',
  },
  text: {
    primary: '#FFFFFF',
    dark: '#FFFFFF',
    contrast: '#222328',
  },
  sidebar: {
    link: '#F2B310',
  },
};

const theme = createTheme({
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.light,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',

          '& .MuiDataGrid-columnHeaderTitle': {
            color: palette.text.dark,
            fontWeight: 600,
          },
          '& .MuiDataGrid-columnHeader': {
            '&:hover': {
              '& > .MuiDataGrid-columnSeparator': {
                display: 'inherit !important',
              },
            },
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none !important',
          },
          '& .MuiDataGrid-row': {
            '&:last-of-type': {
              '& > .MuiDataGrid-cell': {
                borderBottom: 'none',
              },
            },
          },
          '& .MuiDataGrid-cell': {
            paddingLeft: '16px !important',
            borderBottom: '1px solid rgba(81, 81, 81, 0.5);',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: palette.sidebar.link,
          textDecorationColor: palette.sidebar.link,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'unset',
          boxShadow: '0px 4px 12px 3px rgba(0, 0, 0, 0.25)',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: palette.secondary.main,
        },
      },
    },
  },
  palette,
  shape: {
    borderRadius: 5,
  },
  typography: {
    fontFamily: 'Inter',
    strong: {
      fontWeight: 700,
    },
  },
});

export default {
  name: 'ROX Dark',
  theme,
};
