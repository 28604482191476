import {QueryClient, QueryClientProvider} from 'react-query';

import useCustomQueryCache from 'hooks/customQueryCache';

export function CustomQueryClientProvider(props) {
  const {caches} = useCustomQueryCache();
  const {queryCache, mutationCache} = caches;

  const queryClient = new QueryClient({
    queryCache: queryCache,
    mutationCache: mutationCache,
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
    </QueryClientProvider>
  );
}

export default CustomQueryClientProvider;
