import {yupResolver} from '@hookform/resolvers/yup';
import {Box, Container, Paper} from '@mui/material';
import {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import * as Yup from 'yup';

import Button from 'components/button';
import Logo from 'components/logo';
import Page from 'components/page';
import TextField from 'components/text_field';
import useAuth from 'hooks/auth';

const schema = Yup.object().shape({
  password: Yup.string().max(255).required('Password is required'),
  confirmPassword: Yup.string().test(
    'equal',
    'Passwords do not match',
    function (v) {
      const ref = Yup.ref('password');
      return v === this.resolve(ref);
    },
  ),
});

const ResetPassword = () => {
  const {resetPassword} = useAuth();
  const {control, handleSubmit} = useForm({
    resolver: yupResolver(schema),
  });
  const [busy, setBusy] = useState(false);
  const navigate = useNavigate();
  const {id} = useParams();

  const onSubmit = async (data) => {
    const {confirmPassword} = data;
    setBusy(true);
    try {
      await resetPassword({
        reset_password_hash: id,
        password: confirmPassword,
      });

      alert('Reset Successful!');

      navigate('/login');
    } catch (err) {
      alert(err.message);
      setBusy(false);
    }
  };

  return (
    <Page title="Reset Password" sx={{display: 'flex'}}>
      <Container
        maxWidth="md"
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Paper sx={{py: 7, px: 10, mx: 3}}>
          <Box>
            <Logo width={192} />
          </Box>
          <Box flexGrow={1} mt={3}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                fullWidth
                name="password"
                control={control}
                render={(props) => (
                  <TextField {...props} label="Password" type="password" />
                )}
                defaultValue=""
              />
              <Controller
                fullWidth
                name="confirmPassword"
                control={control}
                render={(props) => (
                  <TextField
                    {...props}
                    label="Confirm Password"
                    type="password"
                  />
                )}
                defaultValue=""
              />
              <Box
                mt={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between">
                <Button size="large" type="submit" disabled={busy}>
                  Reset Password
                </Button>
              </Box>
            </form>
          </Box>
        </Paper>
      </Container>
    </Page>
  );
};

export default ResetPassword;
