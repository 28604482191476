import {useTheme} from '@emotion/react';
import PropTypes from 'prop-types';

const Logo = (props) => {
  const {full, circle, ...rest} = props;

  const theme = useTheme();

  let src;
  if (circle) {
    src = '/static/rox_circle.png';
  } else {
    src =
      theme?.palette?.mode === 'dark'
        ? '/static/logo_full_white@3x.png'
        : '/static/logo_full.png';
  }

  return <img alt="Logo" src={src} {...rest} />;
};

Logo.propTypes = {
  cicle: PropTypes.bool,
  full: PropTypes.bool,
};

export default Logo;
