import CloseIcon from '@mui/icons-material/Close';
import {Alert, AlertTitle} from '@mui/lab';
import {IconButton, Slide} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

const POSITIONS = {
  top: '1',
  right: '5',
  bottom: '90',
  left: '80',
  center: '50',
};

const DivContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'vertPos' && prop !== 'horPos',
})(({vertPos, horPos}) => ({
  fontSize: 14,
      boxSizing: 'borderBox',
      position: 'fixed',
      zIndex: 999999,
      top: vertPos,
      right: horPos,
}))

function Toast(props) {
  const {
    color,
    customButtons = [],
    duration = 5000, //Setting duration to zero will keep the Toast showing until onClose is called.
    id,
    icon,
    message,
    onClose,
    screenPos = {vert: 'top', hor: 'center', custom: false},
    severity,
    showXButton = false,
    title,
    variant = 'standard',
  } = props;
  const {vert, hor, custom} = screenPos;
  // For Custom to work the screenPos (padding) value passed in has to have the unit in the string.
  // IE 20px or 47rem.
  // If Custom is false then the key value pairs must be used from POSITIONS.
  const vertPos = custom ? vert : `${POSITIONS[vert]}vh`;
  const horPos = custom ? hor : `${POSITIONS[hor]}vw`;
  const [isHovered, setIsHovered] = useState(false);

  const timeoutRef = useRef();
  useEffect(() => {
    clearTimeout(timeoutRef.current);
    if (duration > 0) {
      timeoutRef.current = setTimeout(() => {
        if (!isHovered && customButtons.length === 0) {
          onClose(id);
        }
      }, duration);
    }
  }, [id, duration, onClose, isHovered, customButtons]);

  const handleMouseLeave = () => {
    if (customButtons.length === 0) {
      onClose(id);
    }
  };

  const xButton = (
    <IconButton
      aria-label="close"
      color="inherit"
      size="small"
      onClick={() => {
        onClose(id);
      }}>
      <CloseIcon fontSize="inherit" />
    </IconButton>
  );

  if (showXButton) {
    customButtons.push(xButton);
  }

  return (
    <DivContainer
      vertPos={vertPos}
      horPos={horPos}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={handleMouseLeave}>
      <Slide direction="down" in={true} mountOnEnter unmountOnExit>
        <Alert
          icon={icon}
          severity={severity}
          color={color}
          variant={variant}
          action={customButtons.map((button) => button)}>
          {title && <AlertTitle>{title}</AlertTitle>}
          {message}
        </Alert>
      </Slide>
    </DivContainer>
  );
}

Toast.propTypes = {
  color: PropTypes.string.isRequired,
  customButtons: PropTypes.array,
  duration: PropTypes.number,
  id: PropTypes.string,
  icon: PropTypes.string,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  screenPos: PropTypes.shape({
    vert: PropTypes.string,
    hor: PropTypes.string,
    custom: PropTypes.string,
  }),
  severity: PropTypes.string.isRequired,
  showXButton: PropTypes.bool,
  title: PropTypes.string,
  variant: PropTypes.string,
};

Toast.defaultProps = {
  color: 'warning',
  message: 'Test',
  onClose: undefined,
  severity: 'warning',
};

export default Toast;
