import PropTypes from 'prop-types';

import useAuth from 'hooks/auth';

export default function MemberRoute(props) {
  const {children, type} = props;

  const {currentMembership} = useAuth();

  if (currentMembership?.membership_type !== type) {
    return <div>access denied. Incorrect membership.</div>;
  }

  return children;
}

MemberRoute.propTypes = {
  type: PropTypes.string,
};
