import {useEffect} from 'react';

export const useDidMount = (fx) => {
  useEffect(() => {
    fx();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export const useDidUnmount = (fx) => {
  useEffect(
    () => () => {
      fx();
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );
};
