import {styled} from '@mui/material/styles';
import {Suspense, lazy, useEffect} from 'react';
import {useQueryClient} from 'react-query';
import {Route, Routes, useParams} from 'react-router-dom';
import {useSetRecoilState} from 'recoil';

import LoadingSpinner from 'components/loading_spinner';
import {pagesState} from 'data/atoms';
import MemberRoute from 'routes/member_route';

const AdminLayout = lazy(() => import('layouts/admin_layout'));
const FormBuilder = lazy(() => import('pages/forms/form_builder'));
const PageBuilder = lazy(() => import('pages/rls_pages/page_builder'));
const BlockBuilder = lazy(() => import('rls/block_builder'));
const RecordLookup = lazy(() => import('pages/record_lookup'));
const ExternalForm = lazy(() => import('pages/forms/form_view'));

const Container = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

export default function SiteLayout() {
  const {orgId, siteId} = useParams();

  const queryClient = useQueryClient();
  const setPagesState = useSetRecoilState(pagesState);

  const key = `${orgId}-${siteId}`;

  // Reset our pagesState when the org changes
  useEffect(
    () => () => {
      setPagesState({});
      queryClient.clear();
    },
    [key, setPagesState, queryClient],
  );

  return (
    <Container>
      <Routes key={key}>
        <Route
          path="/admin/*"
          element={
            <MemberRoute type="admin">
              <Suspense fallback={<LoadingSpinner />}>
                <AdminLayout />
              </Suspense>
            </MemberRoute>
          }
        />
        <Route
          path="/admin/builder/*"
          element={
            <MemberRoute type="admin">
              <Routes>
                <Route
                  path="page/:id"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <PageBuilder />
                    </Suspense>
                  }
                />
                <Route
                  path="form/:id"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <FormBuilder />
                    </Suspense>
                  }
                />
                <Route
                  path="blocks"
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <BlockBuilder />
                    </Suspense>
                  }
                />
              </Routes>
            </MemberRoute>
          }
        />
        <Route
          path="/admin/forms/:id/view"
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <ExternalForm />
            </Suspense>
          }
        />
        <Route path="/portal/*" element={<div>Portal route</div>} />
        <Route
          path="/portal/:id/view"
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <ExternalForm />
            </Suspense>
          }
        />
        <Route
          path="/r/:entity/:id"
          element={
            <Suspense fallback={<LoadingSpinner />}>
              <RecordLookup />
            </Suspense>
          }
        />
      </Routes>
    </Container>
  );
}
