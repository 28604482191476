import {createContext, useCallback, useMemo, useRef} from 'react';
import {MutationCache, QueryCache} from 'react-query';

const CustomQueryCacheContext = createContext();

export function CustomQueryCacheProvider(props) {
  const listenersRef = useRef({});

  const registerListener = useCallback((fx) => {
    const id = Object.keys(listenersRef.current).length + 1;

    listenersRef.current[id] = {fx};

    return () => {
      delete listenersRef.current[id];
    };
  }, []);

  const handleMessage = useCallback((data, query, error) => {
    Object.values(listenersRef.current).forEach(async (l) => {
      if (l) {
        l.fx({data, query, error});
      }
    });
  }, []);

  const queryErrorHandler = useCallback(
    (error, query) => {
      handleMessage({}, query, error);
    },
    [handleMessage],
  );

  const mutSuccessHandler = useCallback(
    (data, variables, mutation) => {
      handleMessage(data, mutation);
    },
    [handleMessage],
  );

  const mutErrorHandler = useCallback(
    (error, mutation) => {
      handleMessage({}, mutation, error);
    },
    [handleMessage],
  );

  const queryCache = new QueryCache({
    onError: (error, query) => {
      queryErrorHandler(error, query);
    },
    onSuccess: (data, query) => {
      handleMessage(data, query);
    },
  });

  const mutationCache = new MutationCache({
    onError: (error, _, __, mutation) => {
      mutErrorHandler(error, mutation);
    },
    onSuccess: (data, variables, _, mutation) => {
      mutSuccessHandler(data, variables, mutation);
    },
  });

  const caches = {
    queryCache,
    mutationCache,
  };

  const values = useMemo(
    () => ({
      registerListener,
    }),
    [registerListener],
  );

  const value = {
    caches,
    values,
  };

  return (
    <CustomQueryCacheContext.Provider value={value}>
      {props.children}
    </CustomQueryCacheContext.Provider>
  );
}

export default CustomQueryCacheContext;
