import MuiTextField from '@mui/material/TextField';

export default function TextField(props) {
  const {field, fieldState, formState, helperText, ...rest} = props;
  const error = fieldState?.error;

  return (
    <MuiTextField
      margin="normal"
      type="text"
      variant="standard"
      fullWidth
      {...field}
      {...rest}
      error={!!error?.message}
      helperText={error?.message || helperText}
    />
  );
}
