/* eslint class-methods-use-this: 0 */

import axios from 'axios';
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';

const MEMBERSHIP_HEADER = 'Membership';

function waitFor(cond, t = 1000) {
  return new Promise((resolve) => {
    function check() {
      setTimeout(() => {
        if (cond()) {
          return resolve();
        }
        check();
      }, t);
    }
    check();
  });
}

class Client {
  constructor() {
    this.baseURL = '/api';
    this.root = '';
    this.log = process.env.NODE_ENV === 'development';

    this.membership = '';
    this.accessExp = 0;

    this.client = axios.create({
      baseURL: this.baseURL,
      transformRequest: [
        (data) =>
          JSON.stringify(data, (name, val) =>
            val === (undefined || '') ? null : val,
          ),
      ],
    });

    this.client.interceptors.request.use(async (config) => {
      try {
        if (this.isRefreshing && config.url !== '/auth/token') {
          await waitFor(() => !this.isRefreshing);
        }

        if (
          dayjs().unix() >= this.accessExp &&
          config.url !== '/login' &&
          config.url !== '/auth/token' &&
          !this.isRefreshing
        ) {
          this.isRefreshing = true;
          const response = await this.refreshToken();
          this.isRefreshing = false;

          const {access} = response;
          const {exp} = jwtDecode(access);
          this.setAccessExp(exp);
        }
      } catch (error) {
        // log error
        this.isRefreshing = false;
      } finally {
        return config;
      }
    });
  }

  prependRoot(path) {
    return `${this.root}${path}`;
  }

  setMembership(membership) {
    this.membership = membership;
  }

  setAccessExp(exp) {
    this.accessExp = exp;
  }

  setRoot(root) {
    this.root = root;
  }

  // Routes =====

  getAttachmentsRoute() {
    return '/attachments';
  }

  getBlocksRoute() {
    return '/rls/blocks';
  }

  getBlueprintsRoute() {
    return '/blueprints';
  }

  getChannelsRoute() {
    return '/channels';
  }

  getCommentsRoute() {
    return '/comments';
  }

  getContactsRoute() {
    return '/contacts';
  }

  getCRMRoute() {
    return '/crm';
  }

  getFilesRoute() {
    return '/files';
  }

  getFormsRoute() {
    return '/forms';
  }

  getItemsRoute() {
    return '/items';
  }

  getItemAffixesRoute() {
    return `${this.getItemsRoute()}/affixes`;
  }

  getItemBinsRoute() {
    return `${this.getItemsRoute()}/bins`;
  }

  getItemGroupsRoute() {
    return `${this.getItemsRoute()}/groups`;
  }

  getItemTypesRoute() {
    return `${this.getItemsRoute()}/types`;
  }

  getItemInventoryAdjustmentsRoute() {
    return `${this.getItemsRoute()}/adjustments`;
  }

  getLabelsRoute() {
    return '/labels';
  }

  getLabelTypesRoute() {
    return '/label-types';
  }

  getLocationsRoute() {
    return '/locations';
  }

  getLoginRoute() {
    return '/login';
  }

  getLogoutRoute() {
    return '/logout';
  }

  getForgotPasswordRoute() {
    return '/forgot-password';
  }

  getResetPasswordRoute() {
    return '/reset-password';
  }

  getManufacturersRoute() {
    return '/manufacturers';
  }

  getMembersRoute() {
    return '/members';
  }

  getMeRoute() {
    return '/me';
  }

  getModulesRoute() {
    return '/modules';
  }

  getNotesRoute() {
    return '/notes';
  }

  getOrganizationsRoute() {
    return '/organizations';
  }

  getPagesRoute() {
    return '/rls/pages';
  }

  getPicklistsRoute() {
    return '/picklists';
  }

  getPortalRoute() {
    return '/portal';
  }

  getPrioritiesRoute() {
    return '/priorities';
  }

  getRegisterRoute() {
    return '/register';
  }

  getRequestsRoute() {
    return '/requests';
  }

  getSitesRoute() {
    return '/sites';
  }

  getStatusesRoute() {
    return '/statuses';
  }

  getSubmissionsRoute() {
    return '/submissions';
  }

  getTasksRoute() {
    return '/tasks';
  }

  getUnitsRoute() {
    return '/units';
  }

  getUnitTypesRoute() {
    return '/unit-types';
  }

  getUsersRoute() {
    return '/users';
  }

  getVendorsRoute() {
    return '/vendors';
  }

  getWorkflowsRoute() {
    return '/workflows';
  }

  // Client calls =====

  changeMyPassword(currentPassword, password) {
    const url = `${this.getMeRoute()}/password`;
    return this.makeRequest(url, 'post', {
      data: {
        current_password: currentPassword,
        password,
      },
      withoutRoot: true,
    });
  }

  getMe() {
    const url = this.getMeRoute();
    return this.makeRequest(url, 'get', {withoutRoot: true});
  }

  patchMe(data) {
    const url = this.getMeRoute();
    return this.makeRequest(url, 'patch', {data, withoutRoot: true});
  }

  // Auth

  login(email, password) {
    const url = this.getLoginRoute();
    return this.makeRequest(url, 'post', {
      data: {
        email,
        password,
      },
      withoutRoot: true,
    });
  }

  logout() {
    const url = this.getLogoutRoute();
    return this.makeRequest(url, 'delete', {withoutRoot: true});
  }

  forgotPassword(email) {
    const url = this.getForgotPasswordRoute();
    return this.makeRequest(url, 'post', {
      data: {
        email,
      },
      withoutRoot: true,
    });
  }

  resetPassword(data) {
    const url = this.getResetPasswordRoute();
    return this.makeRequest(url, 'post', {
      data,
      withoutRoot: true,
    });
  }

  register(data) {
    const url = this.getRegisterRoute();
    return this.makeRequest(url, 'post', {
      data,
      withoutRoot: true,
    });
  }

  authWebsocket() {
    const url = '/ws/auth';
    return this.makeRequest(url, 'get', {
      withoutRoot: true,
    });
  }

  refreshToken() {
    const url = '/auth/token';
    return this.makeRequest(url, 'get', {
      withoutRoot: true,
    });
  }

  // Attachments

  createAttachment(data) {
    const url = this.getAttachmentsRoute();
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  getAttachment(id, params = {}) {
    const url = `${this.getAttachmentsRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getAttachments(params = {}) {
    const url = this.getAttachmentsRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  updateAttachment(data) {
    const url = `${this.getAttachmentsRoute()}/${data.id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  // Blocks

  getBlock(name, params = {}) {
    const url = `${this.getBlocksRoute()}/${name}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getBlocks(params = {}) {
    const url = this.getBlocksRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  // Blueprints

  createBlueprint(data) {
    const url = this.getBlueprintsRoute();
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  getBlueprint(id, params = {}) {
    const url = `${this.getBlueprintsRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getBlueprints(params = {}) {
    const url = this.getBlueprintsRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  loadBlueprint(data) {
    const url = `${this.getBlueprintsRoute()}/load`;
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  updateBlueprint(id, data) {
    const url = `${this.getBlueprintsRoute()}/${id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  // Channels

  createChannelPost(id, data) {
    const url = `${this.getChannelsRoute()}/${id}/posts`;
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  getChannel(id, params = {}) {
    const url = `${this.getChannelsRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getChannelMembers(params = {}) {
    const url = `${this.getChannelsRoute()}/members`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getChannels(params = {}) {
    const url = this.getChannelsRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getChannelPosts(id, params = {}) {
    const url = `${this.getChannelsRoute()}/${id}/posts`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  // Contacts

  createContact(data) {
    const url = this.getContactsRoute();
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  getContact(id, params = {}) {
    const url = `${this.getContactsRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getContacts(params = {}) {
    const url = this.getContactsRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  updateContact(data) {
    const url = `${this.getContactsRoute()}/${data.id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  // CRM

  createCRMConfiguration(data) {
    const url = `${this.getCRMRoute()}/configuration`;
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  createCRMUnit(data) {
    const url = `${this.getCRMRoute()}/units`;
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  getCRMConfiguration(params = {}) {
    const url = `${this.getCRMRoute()}/configuration`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getCRMUnits(params) {
    const url = `${this.getCRMRoute()}/units`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  updateCRMConfiguration(id, data) {
    const url = `${this.getCRMRoute()}/configuration`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  // Files

  getFiles(params = {}) {
    const url = this.getFilesRoute();
    return this.makeRequest(url, 'get');
  }

  downloadFile(id) {
    const url = `${this.getFilesRoute()}/download/${id}`;
    return this.makeRequest(url, 'get');
  }

  // Forms

  createForm(data) {
    const url = this.getFormsRoute();
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  duplicateForm(id) {
    const url = `${this.getFormsRoute()}/${id}/duplicate`;
    return this.makeRequest(url, 'post');
  }

  getForm(id, params = {}) {
    const url = `${this.getFormsRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getFormSubmissions(id, params = {}) {
    const url = `${this.getFormsRoute()}/${id}/submissions`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getForms(params = {}) {
    const url = this.getFormsRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getPublicForm(id) {
    const url = `${this.getFormsRoute()}/public/${id}`;
    return this.makeRequest(url, 'get', {
      withoutRoot: true,
    });
  }

  getSubmissionsByForm(params = {}) {
    const url = `${this.getFormsRoute()}/submissions`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  patchForm(id, data) {
    const url = `${this.getFormsRoute()}/${id}`;
    return this.makeRequest(url, 'patch', {
      data,
    });
  }

  submitForm(id, data) {
    const url = `${this.getFormsRoute()}/${id}/submissions`;
    return this.makeRequest(url, 'post', {
      data: {
        data,
      },
    });
  }

  submitPublicForm(id, data) {
    const url = `${this.getFormsRoute()}/public/${id}`;
    return this.makeRequest(url, 'post', {
      data: {
        data,
      },
      withoutRoot: true,
    });
  }

  updateForm(data) {
    const url = `${this.getFormsRoute()}/${data.id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  // FormLinks

  createFormLink(formId, data) {
    const url = `${this.getFormsRoute()}/${formId}/links`;
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  getFormLink(id, params = {}) {
    const url = `${this.getFormsRoute()}/links/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getLinksForForm(id, params = {}) {
    const url = `${this.getFormsRoute()}/${id}/links`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  patchFormLink(id, data) {
    const url = `${this.getFormsRoute()}/links/${id}`;
    return this.makeRequest(url, 'patch', {
      data,
    });
  }

  updateFormLink(data) {
    const url = `${this.getFormsRoute()}/links/${data.id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  deleteFormLinks(data) {
    const url = `${this.getFormsRoute()}/links`;
    return this.makeRequest(url, 'delete', {
      data,
    });
  }

  // Items

  createItem(data) {
    const url = this.getItemsRoute();
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  getItem(id, params = {}) {
    const url = `${this.getItemsRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getItemStock(id) {
    const url = `${this.getItemsRoute()}/${id}/stock`;
    return this.makeRequest(url, 'get');
  }

  getItems(params = {}) {
    const url = this.getItemsRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  patchItem(id, data) {
    const url = `${this.getItemsRoute()}/${id}`;
    return this.makeRequest(url, 'patch', {
      data,
    });
  }

  updateItem(data) {
    const url = `${this.getItemsRoute()}/${data.id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  // Item Affixes

  createItemAffix(data) {
    const url = this.getItemAffixesRoute();
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  getItemAffix(id, params = {}) {
    const url = `${this.getItemAffixesRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getItemAffixes(params = {}) {
    const url = this.getItemAffixesRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  updateItemAffix(data) {
    const url = `${this.getItemAffixesRoute()}/${data.id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  // Item Bins

  createItemBin(data) {
    const url = this.getItemBinsRoute();
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  getItemBin(id, params = {}) {
    const url = `${this.getItemBinsRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getItemBins(params = {}) {
    const url = this.getItemBinsRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  updateItemBin(data) {
    const url = `${this.getItemBinsRoute()}/${data.id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  // Item Groups

  createItemGroup(data) {
    const url = this.getItemGroupsRoute();
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  getItemGroup(id, params = {}) {
    const url = `${this.getItemGroupsRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getItemGroups(params = {}) {
    const url = this.getItemGroupsRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  updateItemGroup(data) {
    const url = `${this.getItemGroupsRoute()}/${data.id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  // Item Types

  createItemType(data) {
    const url = this.getItemTypesRoute();
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  getItemType(id, params = {}) {
    const url = `${this.getItemTypesRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getItemTypes(params = {}) {
    const url = this.getItemTypesRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  updateItemType(data) {
    const url = `${this.getItemTypesRoute()}/${data.id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  // Item Inventory Adjustments

  createInventoryAdjustment(data) {
    const url = this.getItemInventoryAdjustmentsRoute();
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  getInventoryAdjustment(id, params = {}) {
    const url = `${this.getItemInventoryAdjustmentsRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getInventoryAdjustments(params = {}) {
    const url = this.getItemInventoryAdjustmentsRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  updateInventoryAdjustment(data) {
    const url = `${this.getItemInventoryAdjustmentsRoute()}/${data.id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  // Labels

  createLabel(data) {
    const url = this.getLabelsRoute();
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  getLabel(id, params = {}) {
    const url = `${this.getLabelsRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getLabels(params = {}) {
    const url = this.getLabelsRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  patchLabel(id, data) {
    const url = `${this.getLabelsRoute()}/${id}`;
    return this.makeRequest(url, 'patch', {
      data,
    });
  }

  updateLabel(data) {
    const url = `${this.getLabelsRoute()}/${data.id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  updateLabels(data) {
    const url = `${this.getLabelsRoute()}/batch`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  // LabelTypes

  createLabelType(data) {
    const url = this.getLabelTypesRoute();
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  getLabelType(id, params = {}) {
    const url = `${this.getLabelTypesRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getLabelTypes(params = {}) {
    const url = this.getLabelTypesRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  updateLabelType(data) {
    const url = `${this.getLabelTypesRoute()}/${data.id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  patchLabelType(id, data) {
    const url = `${this.getLabelTypesRoute()}/${id}`;
    return this.makeRequest(url, 'patch', {
      data,
    });
  }

  // Locations

  createLocation(data) {
    const url = this.getLocationsRoute();
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  getLocation(id, params = {}) {
    const url = `${this.getLocationsRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getLocations(params = {}) {
    const url = this.getLocationsRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  updateLocation(data) {
    const url = `${this.getLocationsRoute()}/${data.id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  patchLocation(id, data) {
    const url = `${this.getLocationsRoute()}/${id}`;
    return this.makeRequest(url, 'patch', {
      data,
    });
  }

  // Manufacturers

  createManufacturer(data) {
    const url = this.getManufacturersRoute();
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  getManufacturer(id, params = {}) {
    const url = `${this.getManufacturersRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getManufacturers(params = {}) {
    const url = this.getManufacturersRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  updateManufacturer(data) {
    const url = `${this.getManufacturersRoute()}/${data.id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  // Members

  autocompleteMembers(name, params) {
    const url = `${this.getMembersRoute()}/search`;
    return this.makeRequest(url, 'get', {
      params: {
        name,
        ...params,
      },
    });
  }

  createMember(data) {
    const url = this.getMembersRoute();
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  createAdminMember(data) {
    const url = `${this.getMembersRoute()}/users`;
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  createContactMember(data) {
    const url = `${this.getMembersRoute()}/contacts`;
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  getMember(id, params = {}) {
    const url = `${this.getMembersRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getMemberUnits(id, params = {}) {
    const url = `${this.getMembersRoute()}/${id}/units`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getMembers(params = {}) {
    const url = this.getMembersRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getAdminMembers(params = {}) {
    const url = `${this.getMembersRoute()}/users`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getContactMembers(params = {}) {
    const url = `${this.getMembersRoute()}/contacts`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  patchMember(id, data) {
    const url = `${this.getMembersRoute()}/${id}`;
    return this.makeRequest(url, 'patch', {
      data,
    });
  }

  resetMemberPassword(id, password) {
    const url = `${this.getMembersRoute()}/${id}/reset-password`;
    return this.makeRequest(url, 'patch', {
      data: {
        password,
      },
    });
  }

  // Modules

  getModules() {
    const url = this.getModulesRoute();
    return this.makeRequest(url, 'get');
  }

  // Comments

  createComment(data) {
    const url = this.getCommentsRoute();
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  getComment(id, params = {}) {
    const url = `${this.getCommentsRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getComments(params = {}) {
    const url = this.getCommentsRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  updateComment(data) {
    const url = `${this.getCommentsRoute()}/${data.id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  // Notes

  createNote(data) {
    const url = this.getNotesRoute();
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  getNote(id, params = {}) {
    const url = `${this.getNotesRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getNotes(params = {}) {
    const url = this.getNotesRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  updateNote(data) {
    const url = `${this.getNotesRoute()}/${data.id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  // Organizations

  patchOrganization(data) {
    const url = this.getOrganizationsRoute();
    return this.makeRequest(url, 'patch', {
      data,
    });
  }

  updateOrganizationConfig(data) {
    const url = `${this.getOrganizationsRoute()}/config`;
    return this.makeRequest(url, 'patch', {
      data,
    });
  }

  // Pages

  createPage(data) {
    const url = this.getPagesRoute();
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  createPageBlock(pageId, data) {
    const url = `${this.getPagesRoute()}/${pageId}/blocks`;
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  deletePage(id) {
    const url = `${this.getPagesRoute()}/${id}`;
    return this.makeRequest(url, 'delete');
  }

  getPage(id, params = {}) {
    const url = `${this.getPagesRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getPageLayout(pageId, layoutId, params = {}) {
    const url = `${this.getPagesRoute()}/${pageId}/layouts/${layoutId}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getPageBlocks(id, params = {}) {
    const url = `${this.getPagesRoute()}/${id}/blocks`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getPages(params = {}) {
    const url = this.getPagesRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  patchPage(id, data) {
    const url = `${this.getPagesRoute()}/${id}`;
    return this.makeRequest(url, 'patch', {
      data,
    });
  }

  patchPageBlock(pageId, id, data) {
    const url = `${this.getPagesRoute()}/${pageId}/blocks/${id}`;
    return this.makeRequest(url, 'patch', {
      data,
    });
  }

  updatePage(id, data) {
    const url = `${this.getPagesRoute()}/${id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  // Picklists

  getPicklist(name, params = {}) {
    const url = `${this.getPicklistsRoute()}/${name}`;
    return this.makeRequest(url, 'get', {
      params,
      withoutRoot: true,
    });
  }

  getPicklists(params = {}) {
    const url = this.getPicklistsRoute();
    return this.makeRequest(url, 'get', {
      params,
      withoutRoot: true,
    });
  }

  // Portal

  getPortalForm(id, token) {
    const url = `${this.getPortalRoute()}/forms/${id}`;
    return this.makeRequest(url, 'get', {
      withoutRoot: true,
      headers: {
        token,
      },
    });
  }

  submitPortalForm(id, data, token) {
    const url = `${this.getPortalRoute()}/forms/${id}`;
    return this.makeRequest(url, 'post', {
      withoutRoot: true,
      data,
      headers: {
        token,
      },
    });
  }

  // Priorities

  createPriority(data) {
    const url = this.getPrioritiesRoute();
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  createPriorities(priorities) {
    const url = `${this.getPrioritiesRoute()}/batch`;
    return this.makeRequest(url, 'post', {
      data: priorities,
    });
  }

  getPriority(id, params = {}) {
    const url = `${this.getPrioritiesRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getPriorities(params = {}) {
    const url = this.getPrioritiesRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  updatePriority(id, data) {
    const url = `${this.getPrioritiesRoute()}/${id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  // Requests
  createRequest(request) {
    const url = this.getRequestsRoute();
    return this.makeRequest(url, 'post', {
      data: request,
    });
  }

  createRequestMember(requestId, data) {
    const url = `${this.getRequestsRoute()}/${requestId}/members`;
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  createRequestComment(requestId, text) {
    const url = `${this.getRequestsRoute()}/${requestId}/comments`;
    return this.makeRequest(url, 'post', {
      data: {
        text,
      },
    });
  }

  createRequestConfiguration(data) {
    const url = `${this.getRequestsRoute()}/configurations`;
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  getRequest(requestId, params) {
    const url = `${this.getRequestsRoute()}/${requestId}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getRequestMembers(requestId, params) {
    const url = `${this.getRequestsRoute()}/${requestId}/members`;
    return this.makeRequest(url, 'get', {params});
  }

  getRequestConfiguration(id, params = {}) {
    const url = `${this.getRequestsRoute()}/configurations/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getRequestConfigurations() {
    const url = `${this.getRequestsRoute()}/configurations`;
    return this.makeRequest(url, 'get');
  }

  getLatestRequestConfigurations() {
    const url = `${this.getRequestsRoute()}/configurations/latest`;
    return this.makeRequest(url, 'get');
  }

  getRequestInbox(params = {}) {
    const url = `${this.getRequestsRoute()}/inbox`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getRequests(params = {}) {
    const url = this.getRequestsRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getRequestComments(requestId, params = {}) {
    const url = `${this.getRequestsRoute()}/${requestId}/comments`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getRequestLineItems(requestId, params = {}) {
    const url = `${this.getRequestsRoute()}/${requestId}/line-items`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  deleteRequestMember(requestId, id) {
    const url = `${this.getRequestsRoute()}/${requestId}/members/${id}`;
    return this.makeRequest(url, 'delete');
  }

  patchRequest(requestId, data) {
    const url = `${this.getRequestsRoute()}/${requestId}`;
    return this.makeRequest(url, 'patch', {
      data,
    });
  }

  patchRequestConfigurationVersion(requestId) {
    const url = `${this.getRequestsRoute()}/${requestId}/configuration`;
    return this.makeRequest(url, 'patch', {});
  }

  updateRequestConfiguration(id, data) {
    const url = `${this.getRequestsRoute()}/configurations/${id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  // Sites

  createSite(site) {
    const url = this.getSitesRoute();
    return this.makeRequest(url, 'post', {
      data: site,
    });
  }

  getSite(id, params = {}) {
    const url = `${this.getSitesRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getSites(params = {}) {
    const url = this.getSitesRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  patchSite(data) {
    const url = this.getSitesRoute();
    return this.makeRequest(url, 'patch', {
      data,
    });
  }

  patchSiteModule(data) {
    const url = `${this.getSitesRoute()}/module`;
    return this.makeRequest(url, 'patch', {
      data,
    });
  }

  updateSiteConfig(data) {
    const url = `${this.getSitesRoute()}/config`;
    return this.makeRequest(url, 'patch', {
      data,
    });
  }

  updateSite(data) {
    const url = `${this.getSitesRoute()}/${data.id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  // Statuses

  createStatus(data) {
    const url = this.getStatusesRoute();
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  createStatuses(statuses) {
    const url = `${this.getStatusesRoute()}/batch`;
    return this.makeRequest(url, 'post', {
      data: statuses,
    });
  }

  getStatus(id, params = {}) {
    const url = `${this.getStatusesRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getStatuses(params = {}) {
    const url = this.getStatusesRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  updateStatus(id, data) {
    const url = `${this.getStatusesRoute()}/${id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  // Submissions

  createSubmission(data) {
    const url = this.getSubmissionsRoute();
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  getSubmission(id, params = {}) {
    const url = `${this.getSubmissionsRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getSubmissions(params = {}) {
    const url = this.getSubmissionsRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  updateSubmission(data) {
    const url = `${this.getSubmissionsRoute()}/${data.id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  // Tasks

  createTask(data) {
    const url = this.getTasksRoute();
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  createTaskAssignmentForMembers(id, members) {
    const url = `${this.getTasksRoute()}/${id}/assignment/members`;
    return this.makeRequest(url, 'post', {
      data: {
        members,
      },
    });
  }

  createTaskConfiguration(data) {
    const url = `${this.getTasksRoute()}/configuration`;
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  deleteTask(id) {
    const url = `${this.getTasksRoute()}/${id}`;
    return this.makeRequest(url, 'delete');
  }

  getTasks(params = {}) {
    const url = this.getTasksRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getTask(id, params = {}) {
    const url = `${this.getTasksRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getTaskConfiguration() {
    const url = `${this.getTasksRoute()}/configuration`;
    return this.makeRequest(url, 'get');
  }

  deleteTaskAssignment(id, assignmentId) {
    const url = `${this.getTasksRoute()}/${id}/assignment/${assignmentId}`;
    return this.makeRequest(url, 'delete');
  }

  deleteTaskAssignmentForMembers(id, members) {
    const url = `${this.getTasksRoute()}/${id}/assignment/members`;
    return this.makeRequest(url, 'delete', {
      data: {
        members,
      },
    });
  }

  patchTask(id, data) {
    const url = `${this.getTasksRoute()}/${id}`;
    return this.makeRequest(url, 'patch', {
      data,
    });
  }

  updateTask(id, data) {
    const url = `${this.getTasksRoute()}/${id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  // Units

  addUnitMembers(id, members) {
    const url = `${this.getUnitsRoute()}/${id}/members`;
    return this.makeRequest(url, 'post', {
      data: {
        members,
      },
    });
  }

  createUnit(unit) {
    const url = this.getUnitsRoute();
    return this.makeRequest(url, 'post', {
      data: unit,
    });
  }

  createUnitForm(id, data) {
    const url = `${this.getUnitsRoute()}/${id}/forms`;
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  createUnitLocation(id, data) {
    const url = `${this.getUnitsRoute()}/${id}/location`;
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  createUnitLocations(id, data) {
    const url = `${this.getUnitsRoute()}/${id}/locations`;
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  deleteUnitLocation(unitId, locationId) {
    const url = `${this.getUnitsRoute()}/${unitId}/locations/${locationId}`;
    return this.makeRequest(url, 'delete');
  }

  deleteUnitLocations(unitId, locations) {
    const url = `${this.getUnitsRoute()}/${unitId}/locations`;
    return this.makeRequest(url, 'delete', {
      data: {
        locations,
      },
    });
  }

  deleteUnitMember(unitId, memberId) {
    const url = `${this.getUnitsRoute()}/${unitId}/members/${memberId}`;
    return this.makeRequest(url, 'delete');
  }

  getMyUnitForms(params = {}) {
    const url = this.getFormsRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getUnit(id, params = {}) {
    const url = `${this.getUnitsRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getUnitForms(id, params = {}) {
    const url = `${this.getUnitsRoute()}/${id}/forms`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getUnitLocations(id, params = {}) {
    const url = `${this.getUnitsRoute()}/${id}/locations`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getUnitMembers(id, params = {}) {
    const url = `${this.getUnitsRoute()}/${id}/members`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getUnits(params = {}) {
    const url = this.getUnitsRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  updateUnit(unit) {
    const url = `${this.getUnitsRoute()}/${unit.id}`;
    return this.makeRequest(url, 'put', {
      data: unit,
    });
  }

  patchUnit(id, data) {
    const url = `${this.getUnitsRoute()}/${id}`;
    return this.makeRequest(url, 'patch', {
      data,
    });
  }

  deleteUnits(data) {
    const url = `${this.getUnitsRoute()}`;
    return this.makeRequest(url, 'delete', {
      data,
    });
  }

  importUnits(data) {
    const url = `${this.getUnitsRoute()}/import`;
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  // Unit Types

  createUnitType(unitType) {
    const url = this.getUnitTypesRoute();
    return this.makeRequest(url, 'post', {
      data: unitType,
    });
  }

  getUnitType(id, params = {}) {
    const url = `${this.getUnitTypesRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getUnitTypes(params = {}) {
    const url = this.getUnitTypesRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  updateUnitType(data) {
    const url = `${this.getUnitTypesRoute()}/${data.id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  // Users

  getUsers(params = {}) {
    const url = this.getUsersRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  inviteUsers(invites) {
    const url = `${this.getUsersRoute()}/invite`;
    return this.makeRequest(url, 'post', {
      data: invites,
    });
  }

  // Vendors

  createVendor(data) {
    const url = this.getVendorsRoute();
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  getVendor(id, params = {}) {
    const url = `${this.getVendorsRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getVendors(params = {}) {
    const url = this.getVendorsRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  updateVendor(data) {
    const url = `${this.getVendorsRoute()}/${data.id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  // Workflows

  createWorkflow(data) {
    const url = this.getWorkflowsRoute();
    return this.makeRequest(url, 'post', {
      data,
    });
  }

  getWorkflow(id, params = {}) {
    const url = `${this.getWorkflowsRoute()}/${id}`;
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  getWorkflows(params = {}) {
    const url = this.getWorkflowsRoute();
    return this.makeRequest(url, 'get', {
      params,
    });
  }

  updateWorkflow(id, data) {
    const url = `${this.getWorkflowsRoute()}/${id}`;
    return this.makeRequest(url, 'put', {
      data,
    });
  }

  // Fetch methods =====

  getRequestHeaders() {
    const headers = {
      'Content-Type': 'application/json',
    };

    if (this.membership) {
      headers[MEMBERSHIP_HEADER] = this.membership;
    }

    return headers;
  }

  /*
    request a signed post policy url to upload to
    upon successful completion use the id and name
    to create a new file record
    return the file record.
  */
  async uploadFile(file) {
    let response = await this.makeRequest(
      `${this.getFilesRoute()}/upload/url`,
      'get',
      {
        params: {
          content_type: file.type,
        },
      },
    );

    const {id, name, form_data} = response.results;

    const formData = new FormData();
    Object.keys(form_data).forEach((k) => {
      formData.append(k, form_data[k]);
    });

    formData.append('file', file);

    await axios.post('/files/upload', formData);

    const parts = file.name.split('.');
    const ext = parts[parts.length - 1];

    const f = {
      id,
      name,
      original_name: file.name,
      size: file.size,
      mime_type: file.type,
      extension: ext,
    };

    response = await this.makeRequest(this.getFilesRoute(), 'post', {
      data: f,
    });

    return response;
  }

  async makeRequest(url, method, incomingOptions = {}) {
    const {withoutRoot, ...options} = incomingOptions;
    const params = options?.params ?? {};
    const {query, relations, ...otherParams} = params;

    const mergedParams = {
      ...otherParams,
    };

    if (query && Object.keys(query).length) {
      mergedParams.q = JSON.stringify(query);
    }

    if (relations) {
      mergedParams.relations = relations.join(',');
    }

    const mergedHeaders = {
      ...this.getRequestHeaders(),
      ...options.headers,
    };
    const mergedOptions = Object.assign({}, options, {
      headers: mergedHeaders,
      params: mergedParams,
    });

    try {
      const response = await this.client({
        url: withoutRoot ? url : this.prependRoot(url),
        method,
        ...mergedOptions,
      });

      if (response.status === 200 || response.status === 201) {
        return response.data;
      }

      return {};
    } catch (error) {
      if (this.log) {
        console.log(url, method, mergedOptions, error); // eslint-disable-line
      }

      throw error;
    }
  }
}

export default new Client();
