import {Navigate} from 'react-router-dom';

import useAuth from 'hooks/auth';

export default function ProtectedRoute(props) {
  const {children} = props;

  const {user} = useAuth();

  if (!user?.id) {
    return <Navigate to="/login" replace={true} />;
  }

  return children;
}
