import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions as MuiDiagActions,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {createContext, useCallback, useRef, useState} from 'react';

import TextField from 'components/text_field';

const DialogActions = styled(MuiDiagActions)(({theme}) => ({
  marginBlock: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

const ConfirmDialogContext = createContext();

function ConfirmationDialog(props) {
  const {
    onClose,
    onSuccess,
    title,
    message,
    confirmText = 'Confirm',
    cancelText = 'Cancel',
    destructive,
    challenge,
    challengeMessage = '',
    challengeAnswer,
    challengeErrorMessage = '',
  } = props;

  const [challengeValue, setChallengeValue] = useState('');
  const [challengeFieldState, setChallengeFieldState] = useState({});

  const onChallengeChange = (e) => setChallengeValue(e.target.value);

  const confirm = () => {
    if (challenge && challengeValue !== challengeAnswer) {
      setChallengeFieldState({
        error: {
          message: challengeErrorMessage,
        },
      });
      return;
    }

    onSuccess();
  };

  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
        {challenge && (
          <Box display="flex" alignItems="center" marginBlock={2}>
            <TextField
              fullWidth
              placeholder={challengeMessage}
              onChange={onChallengeChange}
              fieldState={challengeFieldState}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{cancelText}</Button>
        <Button
          variant="contained"
          color={destructive ? 'error' : 'primary'}
          onClick={confirm}
          autoFocus>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ConfirmDialogProvider(props) {
  const {children} = props;

  const [data, setData] = useState({
    show: false,
  });
  const resolver = useRef();

  const showConfirm = useCallback((data) => {
    setData({
      ...data,
      show: true,
    });
    return new Promise((resolve, reject) => {
      resolver.current = resolve;
    });
  }, []);

  const cleanUp = useCallback(() => {
    resolver.current = null;
    setData({show: false});
  }, []);

  const handleSuccess = useCallback(() => {
    resolver.current(true);
    cleanUp();
  }, [cleanUp]);

  const handleClose = useCallback(() => {
    resolver.current(false);
    cleanUp();
  }, [cleanUp]);

  return (
    <ConfirmDialogContext.Provider value={showConfirm}>
      {children}
      {data.show && (
        <ConfirmationDialog
          {...data}
          onSuccess={handleSuccess}
          onClose={handleClose}
        />
      )}
    </ConfirmDialogContext.Provider>
  );
}

export default ConfirmDialogProvider;
export {ConfirmDialogContext};
