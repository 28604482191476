import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-quill/dist/quill.snow.css';
import 'prismjs/prism';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'nprogress/nprogress.css';
import 'assets/css/prism.css';

import {LicenseInfo} from '@mui/x-data-grid-pro';
import ReactDOM from 'react-dom';

import {SettingsProvider} from 'contexts/settings';

import App from './app';
import * as serviceWorker from './serviceWorker';

LicenseInfo.setLicenseKey(
  '60591dc4dd17f993334979ada54d3636T1JERVI6MzAyODAsRVhQSVJZPTE2NjQ4MTk4MTcwMDAsS0VZVkVSU0lPTj0x',
);

ReactDOM.render(
  <SettingsProvider>
    <App />
  </SettingsProvider>,
  document.getElementById('root'),
);

serviceWorker.register();
