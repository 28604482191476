import {createTheme} from '@mui/material';

const theme = {
  palette: {
    mode: 'light',
    primary: {
      main: '#00AB55',
    },
    secondary: {
      main: '#3366FF',
    },
  },
};

export default {
  name: 'Green Days',
  theme: createTheme(theme),
};
