import {createContext} from 'react';
import {atom} from 'recoil';

const organizationState = atom({
  key: 'organization',
  default: {},
});

const OrganizationContext = createContext();

export default function OrganizationProvider(props) {
  return <OrganizationContext.Provider {...props} value={organizationState} />;
}

export {OrganizationContext};
