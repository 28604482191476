import {Box, LinearProgress} from '@mui/material';
import {styled} from '@mui/material/styles';
import NProgress from 'nprogress';
import {useEffect} from 'react';

const DivRoot = styled('div')(({theme}) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  minHeight: '100%',
  padding: theme.spacing(3),
  flex: 1,
}));

const LoadingScreen = () => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <DivRoot>
      <Box width={400}>
        <LinearProgress />
      </Box>
    </DivRoot>
  );
};

export default LoadingScreen;
