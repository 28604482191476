import {useCallback, useContext} from 'react';
import {useMutation} from 'react-query';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {OrganizationContext} from 'contexts/organization';
import Client from 'data/client';

export function useCurrentOrganization() {
  const value = useContext(OrganizationContext);
  return useRecoilValue(value);
}

export function useSetOrganization() {
  const value = useContext(OrganizationContext);
  const setOrganization = useSetRecoilState(value);

  return useCallback(
    (data) => {
      setOrganization((prev) => {
        return {...prev, ...data};
      });
    },
    [setOrganization],
  );
}

export function usePatchOrganization(options = {}) {
  const {onSuccess, ...rest} = options;

  const setOrganization = useSetOrganization();

  const mutation = useMutation(
    (data) => {
      return Client.patchOrganization(data);
    },
    {
      onSuccess: (data) => {
        setOrganization(data.results);
        if (onSuccess) {
          onSuccess();
        }
      },
      ...rest,
    },
  );

  return {
    isPatching: mutation.isLoading,
    patchOrganization: mutation.mutate,
    patchError: mutation.error,
  };
}
