import {Box, LinearProgress} from '@mui/material';
import {styled} from '@mui/material/styles';

const DivRoot = styled('div')(({theme}) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  left: 0,
  padding: theme.spacing(3),
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: 2000,
}));

const SlashScreen = () => {
  return (
    <DivRoot>
      <Box width={400}>
        <LinearProgress />
      </Box>
    </DivRoot>
  );
};

export default SlashScreen;
