import {Global, css} from '@emotion/react';

const GlobalStyles = () => {
  return (
    <>
      <Global
        styles={css`
          @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
        `}
      />
      <Global
        styles={(theme) => ({
          '*': {
            boxSizing: 'border-box',
            margin: 0,
            padding: 0,
          },
          html: {
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',
            height: '100%',
            width: '100%',
          },
          body: {
            height: '100%',
            width: '100%',
          },
          '#root': {
            height: '100%',
            width: '100%',
          },

          'div::-webkit-scrollbar': {
            background: 'none',
            width: 3,
          },

          'div::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.light,
          },

          '.noselect': {
            WebkitTouchCallout: 'none' /* iOS Safari */,
            WebkitUserSelect: 'none' /* Safari */,
            KhtmlUserSelect: 'none' /* Konqueror HTML */,
            MozUserSelect: 'none' /* Old versions of Firefox */,
            msUserSelect: 'none' /* Internet Explorer/Edge */,
            userSelect:
              'none' /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */,
          },
        })}
      />
    </>
  );
};

export default GlobalStyles;
