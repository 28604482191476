import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, {forwardRef, useCallback, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {useLocation} from 'react-router-dom';

import track from 'utils/analytics';

const Container = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  minHeight: '100%',
}));

const Page = forwardRef(({children, title = '', ...rest}, ref) => {
  const location = useLocation();

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: location.pathname,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  return (
    <Container ref={ref} {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Container>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
