/*
The goal for atoms should be to try and colocate them closest to
where they will be used. In some cases that just isn't possible
without have a deeply nested path. This file should be used to
alleviate those issues, but is by no means meant to be a central
place for all atoms to be stored. In short, store them where they
make the most sense.
*/
import {atom} from 'recoil';

export const pagesState = atom({
  key: 'pages',
  default: {},
});
