import blur from './blur';
import greenDays from './green_days';
import roxDark from './rox_dark';
import roxLite from './rox_lite';

export const THEMES = {
  [blur.name]: blur,
  [roxDark.name]: roxDark,
  [roxLite.name]: roxLite,
  [greenDays.name]: greenDays,
  default: roxDark,
};
