import {StyledEngineProvider, ThemeProvider} from '@mui/material';
import {createBrowserHistory} from 'history';
import React, {useEffect} from 'react';
import {ReactQueryDevtools} from 'react-query/devtools';
import {BrowserRouter} from 'react-router-dom';
import {RecoilRoot, useRecoilSnapshot} from 'recoil';
import {THEMES} from 'theme';

import Analytics from 'contexts/analytics';
import {AuthProvider} from 'contexts/auth';
import ConfirmDialogProvider from 'contexts/confirm_dialog';
import {ConversationsProvider} from 'contexts/conversations';
import {CustomQueryCacheProvider} from 'contexts/custom_query_cache_provider';
import {CustomQueryClientProvider} from 'contexts/custom_query_client_provider';
import OrganizationProvider from 'contexts/organization';
import SiteProvider from 'contexts/site';
import {WebsocketProvider} from 'contexts/websocket';
import useSettings from 'hooks/settings';
import Routes from 'routes/routes';

import GlobalStyles from './global_styles';
import MessageManager from './message_manager';
import ScrollReset from './scroll_reset';

function DebugObserver() {
  const snapshot = useRecoilSnapshot();
  useEffect(() => {
    console.debug('The following atoms were modified:');
    for (const node of snapshot.getNodes_UNSTABLE({isModified: true})) {
      console.debug(node.key, snapshot.getLoadable(node));
    }
  }, [snapshot]);

  return null;
}

const history = createBrowserHistory();

const App = () => {
  const {settings} = useSettings();
  const {theme: settingsTheme} = settings;

  const theme = THEMES[settingsTheme].theme;

  return (
    <RecoilRoot>
      <DebugObserver />
      <CustomQueryCacheProvider>
        <CustomQueryClientProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <ConfirmDialogProvider>
                <MessageManager />
                <BrowserRouter history={history}>
                  <OrganizationProvider>
                    <SiteProvider>
                      <AuthProvider>
                        <WebsocketProvider>
                          <ConversationsProvider>
                            <Analytics />
                            <GlobalStyles />
                            <ScrollReset />
                            <Routes />
                          </ConversationsProvider>
                        </WebsocketProvider>
                      </AuthProvider>
                    </SiteProvider>
                  </OrganizationProvider>
                </BrowserRouter>
              </ConfirmDialogProvider>
            </ThemeProvider>
          </StyledEngineProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </CustomQueryClientProvider>
      </CustomQueryCacheProvider>
    </RecoilRoot>
  );
};

export default App;
