import jwtDecode from 'jwt-decode';
import {createContext, useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Client from 'data/client';
import useCustomQueryCache from 'hooks/customQueryCache';
import {useDidMount} from 'hooks/lifecycle';
import {useSetOrganization} from 'hooks/organizations';
import {useSetSite} from 'hooks/sites';
import SplashScreen from 'pages/splash_screen';

const AuthContext = createContext({
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
  forgotPassword: () => {},
  resetPassword: () => Promise.resolve(),
  user: {},
  currentMembership: {},
  setCurrentMembership: () => null,
  switchMembership: () => null,
});

const LAST_MEMBER_KEY = 'last_member_id';
const ACCESS_TOKEN_EXP = 'access_token_exp';

export const AuthProvider = ({children}) => {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState();
  const [currentMembership, setCurrentMembership] = useState();
  const {values} = useCustomQueryCache();
  const {registerListener} = values;
  const navigate = useNavigate();
  const setOrganization = useSetOrganization();
  const setSite = useSetSite();

  const getMe = useCallback(async () => {
    try {
      const response = await Client.getMe();

      const {results} = response;

      setCurrentUser(results);
    } catch (error) {
      console.log('login error', error);
    } finally {
      setLoading(false);
    }
  }, [setOrganization, setSite]); // eslint-disable-line react-hooks/exhaustive-deps

  const login = useCallback(
    async (email, password) => {
      const response = await Client.login(email, password);
      const {access} = response;
      const {exp} = jwtDecode(access);

      window.localStorage.setItem(ACCESS_TOKEN_EXP, exp);

      await getMe();
    },
    [getMe],
  );

  const logout = useCallback(async () => {
    try {
      await Client.logout();
    } catch (err) {
      // TODO: log this somewhere
    } finally {
      Client.setMembership('');
      Client.setToken('');
      window.localStorage.setItem(ACCESS_TOKEN_EXP, 0);

      setTimeout(() => {
        setCurrentUser(null);
      }, 300);
    }
  }, []);

  const forgotPassword = useCallback((props) => {
    const {email} = props;
    Client.forgotPassword(email);
  }, []);

  const resetPassword = useCallback(async (data) => {
    await Client.resetPassword(data);
  }, []);

  const statusCodeLogout = useCallback(
    async (queryData) => {
      if (queryData?.query?.state?.error?.response?.status) {
        const statusCode = queryData.query.state.error.response.status;
        if (statusCode === 401 || statusCode === 403) {
          try {
            await logout();
            navigate('/login?logout=successful');
          } catch (err) {
            console.error(err);
          }
        }
      }
    },
    [logout, navigate],
  );

  useEffect(() => {
    const unregister = registerListener(statusCodeLogout);

    return unregister;
  }, [registerListener, statusCodeLogout]);

  const register = useCallback(async (data) => {
    await Client.register(data);
  }, []);

  const switchMembership = useCallback((id) => {
    window.localStorage.setItem(LAST_MEMBER_KEY, id);

    window.location.href = window.location.origin;
  }, []);

  const value = useMemo(
    () => ({
      login,
      logout,
      forgotPassword,
      resetPassword,
      register,
      user: currentUser,
      currentMembership,
      setCurrentUser,
      setCurrentMembership,
      switchMembership,
    }),
    [login, logout, forgotPassword, resetPassword, register, currentUser, currentMembership, switchMembership],
  );

  useDidMount(() => {
    const exp = window.localStorage.getItem(ACCESS_TOKEN_EXP);
    if (!exp) {
      setLoading(false);
      return;
    }

    Client.setAccessExp(exp);

    getMe();
  });

  if (loading) {
    return <SplashScreen />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
