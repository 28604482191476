import {useCallback, useMemo} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {useContext} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {SiteContext} from 'contexts/site';
import Client from 'data/client';

export function useCurrentSite() {
  const value = useContext(SiteContext);
  return useRecoilValue(value);
}

export function useSetSite() {
  const value = useContext(SiteContext);
  const setSite = useSetRecoilState(value);

  return useCallback(
    (data) => {
      setSite((prev) => {
        return {...prev, ...data};
      });
    },
    [setSite],
  );
}

export default function useSites(id, {params = {}, ...options} = {}) {
  const {data, isLoading, refetch} = useQuery(
    ['sites', id, params],
    () => Client.getSites(params),
    options,
  );

  const sites = data?.results;
  const meta = data?.meta;

  const value = useMemo(
    () => ({
      getSite: refetch,
      isLoading,
      sites: sites || [],
      meta: meta || {},
    }),
    [refetch, isLoading, sites, meta],
  );

  return value;
}

export function useSite(id, {params = {}, ...options} = {}) {
  const {data, isLoading, refetch} = useQuery(
    ['sites', id, params],
    () => Client.getSite(id, params),
    options,
  );

  const site = data?.results;

  const value = useMemo(
    () => ({
      getSite: refetch,
      isLoading,
      site: site || {},
    }),
    [refetch, isLoading, site],
  );

  return value;
}

export function useSaveSite(options = {}) {
  const {onSuccess, ...rest} = options;

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (data) => {
      if (data?.id) {
        return Client.updateSite(data);
      }

      return Client.createSite(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['sites']);
        if (onSuccess) {
          onSuccess();
        }
      },
      ...rest,
    },
  );

  return {
    isSavingSite: mutation.isLoading,
    saveSite: mutation.mutate,
    saveError: mutation.error,
  };
}

export function usePatchSite(options = {}) {
  const {onSuccess, ...rest} = options;

  const setSite = useSetSite();

  const mutation = useMutation(
    (data) => {
      return Client.patchSite(data);
    },
    {
      onSuccess: (data) => {
        setSite(data.results);
        if (onSuccess) {
          onSuccess();
        }
      },
      ...rest,
    },
  );

  return {
    isPatching: mutation.isLoading,
    patchSite: mutation.mutate,
    patchError: mutation.error,
  };
}

export function usePatchSiteModule(options = {}) {
  const {onSuccess, ...rest} = options;

  const setSite = useSetSite();

  const mutation = useMutation(
    (data) => {
      return Client.patchSiteModule(data);
    },
    {
      onSuccess: (data) => {
        setSite(data.results);
        if (onSuccess) {
          onSuccess();
        }
      },
      ...rest,
    },
  );

  return {
    isPatching: mutation.isLoading,
    patchSiteModule: mutation.mutate,
    patchError: mutation.error,
  };
}
