import {useCallback, useEffect, useState} from 'react';

import useCustomQueryCache from 'hooks/customQueryCache';

export function Analytics() {
  const {values} = useCustomQueryCache();
  const {registerListener} = values;
  const [, setDataStore] = useState([]);

  const addAnalytic = useCallback(
    (data) => {
      setDataStore((prev) => [...prev, data]);
    },
    [setDataStore],
  );

  useEffect(() => {
    const unregister = registerListener(addAnalytic);
    return unregister;
  }, [addAnalytic, registerListener]);
  return null;
}

export default Analytics;
