import {useCallback} from 'react';
import {useState} from 'react';

import Toast from 'components/toast';
import useCustomQueryCache from 'hooks/customQueryCache';
import {useDidMount} from 'hooks/lifecycle';

function MessageManager(props) {
  const {values} = useCustomQueryCache();
  const {registerListener} = values;
  const [queue, setQueue] = useState([]);

  const addToQueue = useCallback((message, severity, color) => {
    const newMessage = {id: Date.now(), message, severity, color};
    setQueue((prev) => [...prev, newMessage]);
  }, []);

  const messageHandler = useCallback(
    (newData) => {
      const {data, query, error} = newData;
      if (error) {
        addToQueue(error.toString(), 'warning', 'warning');
      } else if (query.mutationCache && data?.results?.name) {
        addToQueue(
          `${data.results.name} was updated successfully.`,
          'info',
          'info',
        );
      }
    },
    [addToQueue],
  );

  const onClose = useCallback((id) => {
    setQueue((prev) => prev.filter((q) => q.id !== id));
  }, []);

  const registerListeners = () => {
    const unregister = registerListener(messageHandler);
    return unregister;
  };

  useDidMount(() => {
    registerListeners();
  });

  return queue.map((m) => {
    const {id, message, color, severity} = m;
    return (
      <Toast
        key={id}
        id={id}
        message={message}
        color={color}
        severity={severity}
        onClose={onClose}
      />
    );
  });
}

export default MessageManager;
