import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import {Suspense, lazy} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import ErrorBoundary from 'components/error_boundary';
import LoadingScreen from 'pages/loading_screen';
import ResetPassword from 'pages/reset_password';

const ExternalForm = lazy(() => import('pages/forms/form_view'));
const FormLink = lazy(() => import('pages/forms/form_view'));
const Login = lazy(() => import('pages/login'));
const NotFound = lazy(() => import('pages/not_found'));
const AccessDenied = lazy(() => import('pages/access_denied'));
const Register = lazy(() => import('pages/register'));

const RootDiv = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const ContentWrapper = styled('div')(() => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
}));

const Content = styled('div')(() => ({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
}));

const BasicLayout = () => {
  return (
    <ErrorBoundary>
      <RootDiv>
        <ContentWrapper>
          <ContentWrapper>
            <Content>
              <Suspense fallback={<LoadingScreen />}>
                <Routes>
                  <Route
                    path=""
                    exact
                    element={<Navigate to="/m" replace={true} />}
                  />
                  <Route path="login" exact element={<Login />} />
                  <Route path="register/:id" exact element={<Register />} />
                  <Route path="forgot/:id" exact element={<ResetPassword />} />
                  <Route path="forms/link/:id" element={<FormLink />} />
                  <Route path="forms/:id" element={<ExternalForm />} />
                  <Route path="accessdenied" element={<AccessDenied />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Suspense>
            </Content>
          </ContentWrapper>
        </ContentWrapper>
      </RootDiv>
    </ErrorBoundary>
  );
};

BasicLayout.propTypes = {
  children: PropTypes.node,
};

export default BasicLayout;
