import {useEffect, useState} from 'react';
import {Navigate, useParams} from 'react-router-dom';

import LoadingSpinner from 'components/loading_spinner';
import Client from 'data/client';
import useAuth from 'hooks/auth';
import {useSetOrganization} from 'hooks/organizations';
import {useSetSite} from 'hooks/sites';

export default function SiteRoute(props) {
  const {children} = props;

  const [ready, setReady] = useState(false);
  const [invalidSite, setInvalidSite] = useState(false);

  const {orgId: orgIdParam, siteId: siteIdParam} = useParams();

  const setOrganization = useSetOrganization();
  const setSite = useSetSite();
  const {user, setCurrentMembership} = useAuth();
  const {members} = user;

  useEffect(() => {
    setReady(false);

    const member = members.find(
      (m) => m.organization_id === orgIdParam && m.site_id === siteIdParam,
    );

    if (!member) {
      setInvalidSite(true);
      return;
    }

    const {organization, site} = member;
    setOrganization(organization);
    setSite(site);

    setCurrentMembership(member);

    Client.setMembership(member.id);
    Client.setRoot(member.membership_type);

    setReady(true);
  }, [
    orgIdParam,
    siteIdParam,
    members,
    setOrganization,
    setSite,
    setCurrentMembership,
  ]);

  if (invalidSite) {
    return <Navigate to="/m?invalidSite=true" replace={true} />;
  }

  if (!ready) {
    return <LoadingSpinner />;
  }

  return children;
}
