import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {w3cwebsocket} from 'websocket';

import Client from 'data/client';
import useAuth from 'hooks/auth';
import {CONNECTION_STATES} from 'src/constants';

function connectionStateLabel(n) {
  switch (n) {
    case 0:
      return CONNECTION_STATES.CONNECTING;
    case 1:
      return CONNECTION_STATES.CONNECTED;
    case 2:
      return CONNECTION_STATES.CLOSING;
    case 3:
      return CONNECTION_STATES.CLOSED;
    default:
      return CONNECTION_STATES.UNKNOWN;
  }
}

const WebsocketContext = createContext();

export function WebsocketProvider(props) {
  const {currentMembership} = useAuth();

  const listenersRef = useRef({});
  const clientRef = useRef();
  const loggingRef = useRef(false);
  const [connectionState, setConnectionState] = useState(0);

  const registerListener = useCallback((fx) => {
    const id = Object.keys(listenersRef.current).length + 1;

    listenersRef.current[id] = fx;

    return () => {
      delete listenersRef.current[id];
    };
  }, []);

  const handleMessage = useCallback((type, json) => {
    const data = JSON.parse(json);

    if (loggingRef.current) {
      console.log(data);
    }

    Object.values(listenersRef.current).forEach((l) => {
      if (l) {
        l(data);
      }
    });
  }, []);

  const sendMessage = useCallback((message) => {
    if (!clientRef.current) {
      return;
    }

    clientRef.current.send(JSON.stringify(message));
  }, []);

  const setLogging = useCallback((log) => {
    loggingRef.current = log;
  }, []);

  const disconnect = useCallback(() => {
    clientRef.current?.close();
    clientRef.current = null;
    listenersRef.current = {};
  }, []);

  const connect = useCallback(async () => {
    try {
      const token = await Client.authWebsocket();

      const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
      const url = `${protocol}://${window.location.host}/ws?token=${token}`;
      const c = new w3cwebsocket(url);

      c.onclose = (e) => {
        setConnectionState(c.CLOSED);
      };

      c.onerror = (error) => {
        console.error(error);
        setConnectionState(c.CLOSED);
        disconnect();
      };

      c.onopen = () => {
        setConnectionState(c.OPEN);
      };

      c.onmessage = (e) => {
        handleMessage('message', e.data);
      };

      clientRef.current = c;
    } catch (err) {
      console.log(err);
    }
  }, [disconnect, handleMessage]);

  useEffect(() => {
    if (!currentMembership?.id) {
      return undefined;
    }

    connect();

    return disconnect;
  }, [connect, currentMembership, disconnect]);

  const value = useMemo(
    () => ({
      connect,
      disconnect,
      registerListener,
      sendMessage,
      setLogging,
      state: connectionStateLabel(connectionState),
    }),
    [
      connect,
      disconnect,
      registerListener,
      sendMessage,
      setLogging,
      connectionState,
    ],
  );

  return <WebsocketContext.Provider {...props} value={value} />;
}

export default WebsocketContext;
