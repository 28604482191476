import {createTheme} from '@mui/material/styles';

const palette = {
  background: {
    paper: '#FFFFFF',
    default: '#F4F4F4',
  },
  primary: {
    main: '#1086F2',
    light: '#9CC1E3',
    dark: '#046CCB',
    contrast: '#FFFFFF',
    action: '#FFFFFF',
  },
  secondary: {
    main: '#F2B310',
  },
  text: {
    primary: '#343333',
    dark: '#1A1A28',
    contrast: '#F4F4F4',
  },
  sidebar: {
    link: '#1086F2',
  },
};

const theme = createTheme({
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.default,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',

          '& .MuiDataGrid-columnHeaderTitle': {
            color: palette.text.dark,
            fontWeight: 400,
          },
          '& .MuiDataGrid-columnHeader': {
            '&:hover': {
              '& > .MuiDataGrid-columnSeparator': {
                display: 'inherit !important',
              },
            },
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none !important',
          },
          '& .MuiDataGrid-row': {
            '&:last-of-type': {
              '& > .MuiDataGrid-cell': {
                borderBottom: 'none',
              },
            },
          },
          '& .MuiDataGrid-cell': {
            paddingLeft: '16px !important',
            borderBottom: '1px solid rgba(224, 224, 224, .75)',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: palette.sidebar.link,
          textDecorationColor: palette.sidebar.link,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: palette.background.paper,
          boxShadow: '0px 4px 18px 8px rgba(167, 167, 167, 0.14)',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: palette.secondary.main,
        },
      },
    },
  },
  palette,
  shape: {
    borderRadius: 5,
  },
  typography: {
    fontFamily: 'Inter',
    strong: {
      fontWeight: 700,
    },
  },
});

export default {
  name: 'ROX Lite',
  theme,
};
