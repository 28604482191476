import {Suspense, lazy} from 'react';
import {Routes as RRRoutes, Route} from 'react-router-dom';

import LoadingSpinner from 'components/loading_spinner';
import BasicLayout from 'layouts/basic';
import SiteLayout from 'layouts/site';

import ProtectedRoute from './protected_route';
import SiteRoute from './site_route';

const UserMemberships = lazy(() => import('pages/user_memberships'));
const PortalForm = lazy(() => import('pages/forms/portal_form'));

export default function Routes() {
  return (
    <RRRoutes>
      <Route path="*" element={<BasicLayout />} />
      <Route
        path="/app/:orgId/:siteId/*"
        element={
          <ProtectedRoute>
            <SiteRoute>
              <SiteLayout />
            </SiteRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path="/portal/form/:id"
        element={
          <Suspense fallback={<LoadingSpinner />}>
            <PortalForm />
          </Suspense>
        }
      />
      <Route
        path="/m"
        element={
          <ProtectedRoute>
            <Suspense fallback={<LoadingSpinner />}>
              <UserMemberships />
            </Suspense>
          </ProtectedRoute>
        }
      />
    </RRRoutes>
  );
}
