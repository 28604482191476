import {createContext, useCallback, useEffect, useMemo} from 'react';
import {atomFamily, selectorFamily, useSetRecoilState} from 'recoil';

import useWebsocket from 'hooks/websocket';
import {CONNECTION_STATES} from 'src/constants';

const ConversationsContext = createContext();

// Stores messages using the channel id
export const conversationsState = atomFamily({
  key: 'converstationState',
  default: {
    posts: {},
    order: [],
  },
});

// Retrieves a conversation using the channel id
export const conversationStateSelector = selectorFamily({
  key: `conversation/value`,
  get:
    (channel_id) =>
    ({get}) => {
      return get(conversationsState(channel_id));
    },
  set:
    () =>
    ({set}, v) => {
      const {channel_id, data} = v;
      const {id} = data;

      set(conversationsState(channel_id), (prev) => {
        return {
          posts: {
            ...prev.posts,
            [id]: data,
          },
          order: [id, ...prev.order],
        };
      });
    },
});

export function ConversationsProvider(props) {
  const {registerListener, sendMessage, state} = useWebsocket();

  const setConversationState = useSetRecoilState(conversationStateSelector());

  const handleMessage = useCallback(
    (data) => {
      setConversationState(data);
    },
    [setConversationState],
  );

  useEffect(() => {
    if (state !== CONNECTION_STATES.CONNECTED) {
      return undefined;
    }

    const unregister = registerListener(handleMessage);

    return unregister;
  }, [handleMessage, registerListener, state]);

  const value = useMemo(
    () => ({
      conversationStateSelector,
      sendMessage,
    }),
    [sendMessage],
  );

  return <ConversationsContext.Provider value={value} {...props} />;
}

export default ConversationsContext;
