export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  MINIMAL: 'MINIMAL',
  ROX: 'ROX',
  UNICORN: 'UNICORN',
  UNITED: 'UNITED',
};

export const MEMBERSHIP_TYPES = {
  ADMIN: 'admin',
  CONTACT: 'contact',
};

export const CONNECTION_STATES = {
  CONNECTING: 'connection',
  CONNECTED: 'connected',
  CLOSING: 'closing',
  CLOSED: 'closed',
  UNKNOWN: 'unknown',
};

export const MODULES = [
  {
    name: 'forms',
    title: 'Forms',
  },
  {
    name: 'crm',
    title: 'Relationships',
  },
  {
    name: 'requests',
    title: 'Requests',
  },
  {
    name: 'tasks',
    title: 'Tasks',
  },
];

export const MODULES_MAP = MODULES.reduce(
  (acc, m) => ({...acc, [m.name]: m}),
  {},
);

export const ENTITIES = [
  {
    name: 'form',
    title: 'Form',
  },
  {
    name: 'submission',
    title: 'Submission',
  },
  {
    name: 'request',
    title: 'Request',
  },
  {
    name: 'unit',
    title: 'Unit',
  },
  {
    name: 'contact',
    title: 'Contact',
  },
  {
    name: 'task',
    title: 'Task',
  },
];

export const ENTITIES_MAP = ENTITIES.reduce(
  (acc, e) => ({...acc, [e.name]: e}),
  {},
);
