import {createContext} from 'react';
import {atom} from 'recoil';

const siteState = atom({
  key: 'site',
  default: {},
});

const SiteContext = createContext();

export default function SiteProvider(props) {
  return <SiteContext.Provider {...props} value={siteState} />;
}

export {SiteContext};
